import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
// import { useFormationsNew } from "../../hooks/useFormationsNew";
import { usePrevious } from "../../hooks/usePrevious";
import DropDown from "../../components/CustomComponents/DropDown/DropDown";
import Input from "../CustomComponents/Input/Input";
import { confirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import useWindowDimensions from "../AddNewCanvas/useWindowDimensions";
import { GetSportsGroundBySportsId } from "../../store/action/sportsAction";
import { GetPlayById, UpdatePlay } from "../../store/action/canvasAction";
import {
  CANVAS_BY_ID_SUCCESS,
  RESET_UPDATE_CANVAS_SUCCESS,
} from "../../store/constants/canvas";
import { GetAllFolderList } from "../../store/action/canvasFolderAction";
import { PLAYBOOK_ACTIVE_TAB_INDEX } from "../../store/constants/playbookTab";
import { EVENT_SCREENS } from "../../configue/firebase/EventConstants";
import FirebaseLogEvents from "../../configue/firebase/LogEvents";
// import SetFreeDraw from "../components/AddNewCanvas/SetFreeDraw";

function WhiteBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfileData } = useSelector((state) => state.user);
  const { sportsGroundBySportsIdData } = useSelector((state) => state.sports);
  const canvasFreeDrawRef = React.useRef(null);
  const contextFreeDrawRef = React.useRef(null);
  const [isClear, setIsClear] = React.useState(false);
  const toast = React.useRef(null);
  const { state } = useLocation();
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [isErase, setIsErase] = React.useState(false);
  const [folderList, setFolderList] = React.useState([]);
  const [hierarchy, sethierarchy] = React.useState([]);
  const [folderTitle, setFolderTitle] = React.useState("");
  const [folderError, setFolderError] = React.useState(false);
  const [selectedSportGround, setSelectedSportGround] = React.useState();
  const { getPlaybookDetailsData } = useSelector((state) => state.canvasFolder);
  const [dragStartLocation, setDragStartLocation] = React.useState({
    x: 0,
    y: 0,
  });
  const [list, setList] = React.useState([]);
  const [poppedList, setPoppedList] = React.useState([]);
  const [eraseSize, setEraseSize] = React.useState();
  // const [coordinates, setCoordinates] = React.useState([]);canvasByIdData
  const { canvasByIdData, updatePlayData } = useSelector(
    (state) => state.canvas
  );
  const [imagePath, setImagePath] = React.useState("");
  const [animeData, setAnimeData] = React.useState([]);
  const [radius, setRadius] = React.useState(0);
  const [snapshot, setsnapshot] = React.useState({ x: 0, y: 0 });
  const [currentParentId, setCurrentParentId] = React.useState("0");
  const [prevFreeDrawType, setPrevFreeDrawType] = React.useState("");
  const [freeDrawType, setFreeDrawType] = React.useState("Pencile");
  const [freeDrawColor, setFreeDrawColor] = React.useState("#000000");

  const [isData, setIsData] = React.useState(true);

  const freeDrawTypeData = React.useMemo(
    () => [
      { id: "Pencile", label: "Pencile" },
      { id: "Line", label: "Line" },
      { id: "Circle", label: "Circle" },
    ],
    []
  );
  const dimentions = useWindowDimensions();
  const prevDimentions = usePrevious(dimentions);

  React.useEffect(() => {
    if (state && state.ePlaybookType && state.ePlaybookType === "My Playbook") {
      FirebaseLogEvents("create_whiteboard", EVENT_SCREENS.my_whiteboard);
    } else if (
      state &&
      state.ePlaybookType &&
      state.ePlaybookType === "Team Playbook"
    ) {
      FirebaseLogEvents("create_whiteboard", EVENT_SCREENS.team_whiteboard);
    }
  }, [state]);

  const percentageToPX = React.useCallback(
    (freeDraw) => {
      const newList = [];
      for (let i = 0; i < freeDraw.length; i++) {
        const val = {
          vStartX: freeDraw[i].vStart.x,
          vStartY: freeDraw[i].vStart.y,
          vEndX: freeDraw[i].vEnd.x,
          vEndY: freeDraw[i].vEnd.y,
          vRadius: freeDraw[i].vRadius,
          vCoordinate: freeDraw[i].vCoordinate,
          eType: freeDraw[i].eType,
          vColor: freeDraw[i].vLineColor,
          vLineColor: freeDraw[i].vLineColor,
          eEraseType: freeDraw[i].eEraseType,
          iListId: freeDraw[i].iListId,
          iEraseSize: freeDraw[i].iEraseSize,
        };
        val.vStartX = (val.vStartX * dimentions.width) / 100;
        val.vStartY = (val.vStartY * dimentions.height) / 100;
        val.vEndX = (val.vEndX * dimentions.width) / 100;
        val.vEndY = (val.vEndY * dimentions.height) / 100;

        if (val.vCoordinate && val.vCoordinate.length > 0) {
          for (let k = 0; k < val.vCoordinate.length; k++) {
            val.vCoordinate[k].x =
              (val.vCoordinate[k].x * dimentions.width) / 100;
            val.vCoordinate[k].y =
              (val.vCoordinate[k].y * dimentions.height) / 100;
          }
        }
        newList.push(val);
      }
      return newList;
    },
    [dimentions]
  );

  const pxToPercentage = React.useCallback(
    (list) => {
      const freeDraw = [];
      for (let t = 0; t < list.length; t++) {
        const trans = list[t];
        if (trans.vStartY && trans.vStartX) {
          trans.vStartX = (trans.vStartX * 100) / dimentions.width;
          trans.vStartY = (trans.vStartY * 100) / dimentions.height;
          trans.vEndX = (trans.vEndX * 100) / dimentions.width;
          trans.vEndY = (trans.vEndY * 100) / dimentions.height;
          for (let k = 0; k < trans.vCoordinate.length; k++) {
            if (k === 0) {
              trans.vCoordinate[k].x = trans.vStartX;
              trans.vCoordinate[k].y = trans.vStartY;
            } else if (k === trans.vCoordinate.length - 1) {
              trans.vCoordinate[k].x = trans.vEndX;
              trans.vCoordinate[k].y = trans.vEndY;
            } else {
              trans.vCoordinate[k].x =
                (trans.vCoordinate[k].x * 100) / dimentions.width;
              trans.vCoordinate[k].y =
                (trans.vCoordinate[k].y * 100) / dimentions.height;
            }
          }
        }
      }

      for (let t = 0; t < list.length; t++) {
        freeDraw.push({
          vStart: {
            x: list[t].vStartX,
            y: list[t].vStartY,
          },
          vEnd: {
            x: list[t].vEndX,
            y: list[t].vEndY,
          },
          eType: list[t].eType,
          vRadius: list[t].vRadius,
          vLineColor: list[t].vLineColor,
          vColor: list[t].vLineColor,
          vCoordinate: list[t].vCoordinate,
          eEraseType: list[t].eEraseType,
          iListId: list[t].iListId,
          iEraseSize: list[t].iEraseSize,
        });
      }
      return freeDraw;
    },
    [dimentions]
  );

  React.useEffect(() => {
    if (isClear) {
      setIsClear(false);
    }
  }, [isClear]);

  // get Folder treee

  React.useEffect(() => {
    if (
      state &&
      state.ePlaybookType &&
      (state.ePlaybookType === "Team Playbook" ||
        state.ePlaybookType === "My Playbook")
    ) {
      dispatch(
        GetAllFolderList({
          ePlaybookType: state.ePlaybookType,
          iTeamIds: [state?.iTeamId],
          iUserId: sessionStorage.getItem("id"),
          iParentId: state.currentParentId || "0",
        })
      );
    }
  }, [state, dispatch]);

  React.useEffect(() => {
    if (
      getPlaybookDetailsData &&
      getPlaybookDetailsData.code === "200" &&
      getPlaybookDetailsData.data &&
      Object.keys(getPlaybookDetailsData.data).length > 0
    ) {
      if (
        getPlaybookDetailsData.data.hierarchy &&
        getPlaybookDetailsData.data.hierarchy.length > 0
      ) {
        sethierarchy([
          {
            vPlayBook: "Home",
            iParentId: "",
          },
          ...getPlaybookDetailsData.data.hierarchy,
        ]);
      } else {
        sethierarchy([
          {
            vPlayBook: "Home",
            iParentId: "",
          },
        ]);
      }
      if (
        getPlaybookDetailsData.data.folder &&
        getPlaybookDetailsData.data.folder.length > 0
      ) {
        setFolderList(getPlaybookDetailsData.data.folder);
      } else {
        setFolderList([]);
      }
    } else {
      setFolderList([]);
      sethierarchy([]);
    }
  }, [getPlaybookDetailsData]);

  // update whiteboard play message
  React.useEffect(() => {
    if (updatePlayData && updatePlayData?.code === "200") {
      toast.current.show({
        severity: "success",
        summary: updatePlayData?.message,
        life: 2000,
      });
      setTimeout(() => {
        dispatch({ type: RESET_UPDATE_CANVAS_SUCCESS });
      }, 3000);

      // dispatch(ResetCanvasStore());
      // if (
      //   (state && state?.iPlaybookId) ||
      //   sessionStorage.getItem("iPlaybookId")
      // ) {
      //   const values = {
      //     iPlaybookId:
      //       state && state?.iPlaybookId
      //         ? state?.iPlaybookId
      //         : sessionStorage.getItem("iPlaybookId"),
      //   };
      //   dispatch(GetPlayById(values));
      // }
      // dispatch(GetCanvasPlayerCoordinates(values));
    }
  }, [updatePlayData, dispatch]);

  // get sports ground
  React.useEffect(() => {
    const values = {
      iSportId: sessionStorage.getItem("sportId"),
    };
    dispatch(GetSportsGroundBySportsId(values));
    // }
  }, [dispatch]);

  // set sports ground
  React.useEffect(() => {
    if (
      sportsGroundBySportsIdData &&
      sportsGroundBySportsIdData?.code === "200"
    ) {
      const path = sportsGroundBySportsIdData?.data.filter(
        (item) =>
          item.iSport_groundImageId ===
          sportsGroundBySportsIdData?.data[0].iSport_groundImageId
      );
      setSelectedSportGround(
        sportsGroundBySportsIdData?.data[0].iSport_groundImageId
      );
      setImagePath(
        path.length > 0
          ? path[0].vSport_groundImage
          : sportsGroundBySportsIdData?.data[0].vBallImage
      );
    }
  }, [setImagePath, sportsGroundBySportsIdData]);

  // GetWhiteBoardById APi call
  React.useEffect(() => {
    if (
      (state && state?.iPlaybookId) ||
      sessionStorage.getItem("iPlaybookId")
    ) {
      dispatch(
        GetPlayById({
          iPlaybookId:
            state?.iPlaybookId || sessionStorage.getItem("iPlaybookId"),
        })
      );
    }
  }, [state, dispatch]);

  React.useEffect(() => {
    if (
      isData &&
      canvasByIdData &&
      canvasByIdData.code === "200" &&
      canvasByIdData.data &&
      canvasByIdData.data.vWhiteBoardDraw &&
      canvasByIdData.data.vWhiteBoardDraw.length > 0
    ) {
      const data = percentageToPX(
        JSON.parse(canvasByIdData.data.vWhiteBoardDraw)
      );
      if (data.length > 0) {
        setList(data);
        for (let i = 0; i < data.length; i++) {
          contextFreeDrawRef.current.lineWidth = data[i].iEraseSize + 5;
          if (data[i].eEraseType === "No") {
            contextFreeDrawRef.current.globalCompositeOperation = "source-over";
            contextFreeDrawRef.current.strokeStyle =
              data[i]?.vColor || data[i]?.vLineColor;
          } else {
            contextFreeDrawRef.current.globalCompositeOperation =
              "destination-out";
            contextFreeDrawRef.current.strokeStyle = "rgba(0,0,0,1)";
          }
          if (data[i].vCoordinate && data[i].vCoordinate.length > 0) {
            for (let j = 0; j < data[i].vCoordinate.length; j++) {
              const currentCoordinate = data[i].vCoordinate[j];
              const nextCoordinate =
                j === data[i].vCoordinate.length - 1
                  ? data[i].vCoordinate[j]
                  : data[i].vCoordinate[j + 1];
              contextFreeDrawRef.current.beginPath();
              contextFreeDrawRef.current.moveTo(
                currentCoordinate.x,
                currentCoordinate.y
              );
              contextFreeDrawRef.current.lineTo(
                nextCoordinate.x,
                nextCoordinate.y
              );
              contextFreeDrawRef.current.stroke();
            }
          } else {
            contextFreeDrawRef.current.beginPath();
            contextFreeDrawRef.current.moveTo(data[i].vStartX, data[i].vStartY);
            contextFreeDrawRef.current.lineTo(data[i].vEndX, data[i].vEndY);
            contextFreeDrawRef.current.stroke();
          }
          if (i === data.length - 1) {
            contextFreeDrawRef.current.globalCompositeOperation = "source-over";
            contextFreeDrawRef.current.strokeStyle = freeDrawColor;
          }
        }
      }
      setIsData(false);
      contextFreeDrawRef.current.lineWidth = 5;
    }
  }, [
    canvasByIdData,
    isData,
    percentageToPX,
    freeDrawColor,
    contextFreeDrawRef,
  ]);

  // redraw all canvas details
  const reDraw = React.useCallback(() => {
    contextFreeDrawRef.current.clearRect(
      0,
      0,
      canvasFreeDrawRef.current.width,
      canvasFreeDrawRef.current.height
    );
    for (let i = 0; i < list.length; i++) {
      contextFreeDrawRef.current.strokeStyle =
        list[i]?.vLineColor || list[i]?.vColor;
      if (list[i].vStartX > 0 && list[i].vStartY > 0) {
        if (list[i].vCoordinate && list[i].vCoordinate.length > 0) {
          for (let k = 0; k < list[i].vCoordinate.length; k++) {
            const currentCoordinate = list[i].vCoordinate[k];
            const nextCoordinate =
              k === list[i].vCoordinate.length - 1
                ? list[i].vCoordinate[k]
                : list[i].vCoordinate[k + 1];
            contextFreeDrawRef.current.beginPath();
            contextFreeDrawRef.current.moveTo(
              currentCoordinate.x,
              currentCoordinate.y
            );
            contextFreeDrawRef.current.lineTo(
              nextCoordinate.x,
              nextCoordinate.y
            );
            contextFreeDrawRef.current.stroke();
          }
        } else {
          if (list[i].eType === "Circle") {
            contextFreeDrawRef.current.beginPath();
            contextFreeDrawRef.current.arc(
              list[i].vStartX,
              list[i].vStartY,
              list[i].vRadius,
              0,
              2 * Math.PI,
              false
            );
            contextFreeDrawRef.current.stroke();
          } else {
            contextFreeDrawRef.current.beginPath();
            contextFreeDrawRef.current.moveTo(list[i].vStartX, list[i].vStartY);
            contextFreeDrawRef.current.lineTo(list[i].vEndX, list[i].vEndY);
            contextFreeDrawRef.current.stroke();
          }
        }
      }
    }
  }, [list]);

  // re-draw on dimentions change
  React.useEffect(() => {
    if (
      dimentions &&
      prevDimentions &&
      dimentions.height !== prevDimentions.hight &&
      dimentions.width !== prevDimentions.width
    ) {
      reDraw();
    }
  }, [dimentions, reDraw, prevDimentions]);

  // canvas setup
  React.useLayoutEffect(() => {
    const canvasFreeDraw = canvasFreeDrawRef.current;
    canvasFreeDraw.width = dimentions.width * 2;
    canvasFreeDraw.height = dimentions.height * 2;
    canvasFreeDraw.style.width = `${dimentions.width}px`;
    canvasFreeDraw.style.height = `${dimentions.height}px`;

    const contextFreeDraw = canvasFreeDraw.getContext("2d");
    contextFreeDraw.scale(2, 2);
    contextFreeDraw.strokeStyle = "black";
    // contextFreeDraw.lineCap = "round";
    contextFreeDraw.fillStyle = "black";
    contextFreeDraw.lineWidth = 5;
    contextFreeDrawRef.current = contextFreeDraw;
  }, [dimentions, canvasFreeDrawRef, contextFreeDrawRef]);

  // get coordinate
  const getCanvasCoordinates = React.useCallback(
    (event) => {
      const canvas = canvasFreeDrawRef.current;
      const x = event.clientX - canvas.getBoundingClientRect().left;
      const y = event.clientY - canvas.getBoundingClientRect().top;

      return { x: x, y: y };
    },
    [canvasFreeDrawRef]
  );

  // draw arrow
  // const drawArrow = React.useCallback(
  //   (position) => {
  //     contextFreeDrawRef.current.fillStyle = "red";
  //     contextFreeDrawRef.current.beginPath();
  //     contextFreeDrawRef.current.moveTo(
  //       dragStartLocation.x,
  //       dragStartLocation.y
  //     );
  //     contextFreeDrawRef.current.lineTo(position.x, position.y);
  //     contextFreeDrawRef.current.stroke();
  //     const x_center = position.x;
  //     const y_center = position.y;
  //     const r = 10;

  //     let angle = 0;
  //     let x = 0;
  //     let y = 0;

  //     contextFreeDrawRef.current.beginPath();

  //     angle = Math.atan2(
  //       position.y - dragStartLocation.y,
  //       position.x - dragStartLocation.x
  //     );
  //     x = r * Math.cos(angle) + x_center;
  //     y = r * Math.sin(angle) + y_center;

  //     contextFreeDrawRef.current.moveTo(x, y);

  //     angle += (1 / 3) * (2 * Math.PI);
  //     x = r * Math.cos(angle) + x_center;
  //     y = r * Math.sin(angle) + y_center;

  //     contextFreeDrawRef.current.lineTo(x, y);

  //     angle += (1 / 3) * (2 * Math.PI);
  //     x = r * Math.cos(angle) + x_center;
  //     y = r * Math.sin(angle) + y_center;

  //     contextFreeDrawRef.current.lineTo(x, y);
  //     contextFreeDrawRef.current.closePath();
  //     contextFreeDrawRef.current.fill();
  //   },
  //   [dragStartLocation, contextFreeDrawRef]
  // );

  // draw straight line
  const drawLine = React.useCallback(
    (position) => {
      // if (isFreeDraw) {
      contextFreeDrawRef.current.beginPath();
      contextFreeDrawRef.current.moveTo(
        dragStartLocation.x,
        dragStartLocation.y
      );
      contextFreeDrawRef.current.lineTo(position.x, position.y);
      contextFreeDrawRef.current.stroke();
      // }
    },
    [dragStartLocation]
  );

  // draw circle
  const drawCircle = React.useCallback(
    (position) => {
      // if (isFreeDraw) {
      const radius = Math.sqrt(
        Math.pow(dragStartLocation.x - position.x, 2) +
          Math.pow(dragStartLocation.y - position.y, 2)
      );
      setRadius(radius);
      contextFreeDrawRef.current.beginPath();
      contextFreeDrawRef.current.arc(
        dragStartLocation.x,
        dragStartLocation.y,
        radius,
        0,
        2 * Math.PI,
        false
      );
      contextFreeDrawRef.current.stroke();
      // }
    },
    [contextFreeDrawRef, setRadius, dragStartLocation]
  );

  // on player select start draw
  const handleStartDraw = React.useCallback(
    (e) => {
      setIsDrawing(true);
      const { offsetX, offsetY } = e.nativeEvent;

      if (freeDrawType === "Line") {
        contextFreeDrawRef.current.beginPath();
        contextFreeDrawRef.current.moveTo(offsetX, offsetY);
        setDragStartLocation(getCanvasCoordinates(e));
        setsnapshot(
          contextFreeDrawRef.current.getImageData(
            0,
            0,
            canvasFreeDrawRef.current.width,
            canvasFreeDrawRef.current.height
          )
        );
      } else if (freeDrawType === "Circle") {
        contextFreeDrawRef.current.beginPath();
        contextFreeDrawRef.current.moveTo(offsetX, offsetY);
        setDragStartLocation(getCanvasCoordinates(e));
        setsnapshot(
          contextFreeDrawRef.current.getImageData(
            0,
            0,
            canvasFreeDrawRef.current.width,
            canvasFreeDrawRef.current.height
          )
        );
      } else {
        contextFreeDrawRef.current.beginPath();
        contextFreeDrawRef.current.moveTo(offsetX, offsetY);
        setDragStartLocation({ x: offsetX, y: offsetY });
        setAnimeData([...animeData, { x: offsetX, y: offsetY }]);
      }
    },
    [
      contextFreeDrawRef,
      canvasFreeDrawRef,
      getCanvasCoordinates,
      animeData,
      freeDrawType,
    ]
  );

  const handleFinishDraw = React.useCallback(
    (e) => {
      if (dragStartLocation.x !== 0 && dragStartLocation.y !== 0) {
        if (freeDrawType) {
          setIsDrawing(false);
          const endData = getCanvasCoordinates(e);
          const arrary = {
            iListId: list.length + 1,
            vStartX: dragStartLocation.x,
            vStartY: dragStartLocation.y,
            vEndX: endData.x,
            vEndY: endData.y,
            vRadius: radius,
            vCoordinate: animeData,
            eType: freeDrawType,
            vLineColor: freeDrawColor,
            vColor: freeDrawColor,
            eEraseType: isErase ? "Yes" : "No",
            iEraseSize: eraseSize,
          };
          setList([...list, arrary]);
          setPoppedList([]);
          if (freeDrawType === "Line") {
            contextFreeDrawRef.current.lineTo(endData.x, endData.y);
            contextFreeDrawRef.current.stroke();
            contextFreeDrawRef.current.putImageData(snapshot, 0, 0);
            drawLine(getCanvasCoordinates(e));
          } else if (freeDrawType === "Circle") {
            contextFreeDrawRef.current.putImageData(snapshot, 0, 0);
            drawCircle(getCanvasCoordinates(e));
          }
          setAnimeData([]);
        }
        setDragStartLocation({
          x: 0,
          y: 0,
        });
      }
    },
    [
      freeDrawColor,
      // isFreeDraw,
      list,
      animeData,
      setAnimeData,
      dragStartLocation,
      radius,
      setIsDrawing,
      drawLine,
      snapshot,
      drawCircle,
      getCanvasCoordinates,
      freeDrawType,
      isErase,
      eraseSize,
    ]
  );

  // on draw set up data in array
  const handleDraw = React.useCallback(
    (e) => {
      // if (isFreeDraw && freeDrawType) {
      if (freeDrawType) {
        if (!isDrawing) {
          return;
        }
        setRadius(0);
        const { offsetX, offsetY } = e.nativeEvent;
        if (freeDrawType === "Line") {
          contextFreeDrawRef.current.putImageData(snapshot, 0, 0);
          drawLine(getCanvasCoordinates(e));
        } else if (freeDrawType === "Circle") {
          contextFreeDrawRef.current.putImageData(snapshot, 0, 0);
          drawCircle(getCanvasCoordinates(e));
        } else {
          setAnimeData([...animeData, getCanvasCoordinates(e)]);
          contextFreeDrawRef.current.lineTo(offsetX, offsetY);
          contextFreeDrawRef.current.stroke();
        }
      } else {
        const { offsetX, offsetY } = e.nativeEvent;
        contextFreeDrawRef.current.globalCompositeOperation = "destination-out";
        contextFreeDrawRef.current.arc(
          offsetX,
          offsetY,
          8,
          0,
          Math.PI * 2,
          false
        );
        contextFreeDrawRef.current.fill();
      }
    },
    [
      animeData,
      setAnimeData,
      setRadius,
      getCanvasCoordinates,
      drawLine,
      drawCircle,
      snapshot,
      isDrawing,
      // isFreeDraw,
      freeDrawType,
    ]
  );

  // canvas click events
  const handleCanvasClick = React.useCallback((event) => {}, []);

  const handleErase = React.useCallback(
    (size) => {
      setEraseSize(size);
      contextFreeDrawRef.current.globalCompositeOperation = "destination-out";

      contextFreeDrawRef.current.lineWidth = size + 5;

      contextFreeDrawRef.current.strokeStyle = "rgba(0,0,0,1)";
      setFreeDrawType("Pencile");
      setPrevFreeDrawType(freeDrawType);
    },
    [freeDrawType]
  );

  const handleEraseStop = React.useCallback(() => {
    contextFreeDrawRef.current.globalCompositeOperation = "source-over";
    contextFreeDrawRef.current.lineWidth = 5;
    contextFreeDrawRef.current.strokeStyle = freeDrawColor;
    setFreeDrawType(prevFreeDrawType);
    setPrevFreeDrawType("");
  }, [freeDrawColor, prevFreeDrawType]);

  const handleSave = React.useCallback(
    async (event) => {
      event.preventDefault();
      let errors = {};
      let values = {};
      for (let i = 0; i < event.target.length - 1; i++) {
        if (event.target[i].value === "" && event.target[i].name !== "") {
          errors = {
            ...errors,
            [event.target[i].name]: true,
          };
        }
        if (event.target[i].name !== "") {
          values = {
            ...values,
            [event.target[i].name]: event.target[i].value,
          };
        }
      }
      const newList = pxToPercentage(list);
      values = {
        ...values,
        iUserId: sessionStorage.getItem("id"),
        vWhiteBoardDraw: JSON.stringify(newList),
        iSport_groundImageId: selectedSportGround,
        iTeamId: 0,
        iParentId:
          currentParentId !== "0" ? currentParentId : state?.currentParentId,
        iSportId:
          state && state?.iSportId
            ? state?.iSportId
            : sessionStorage.getItem("sportId"),
        eAccess: "view",
        eType: "Whiteboard",
      };
      if (state && state.ePlaybookType === "Team Playbook") {
        values = {
          ...values,
          ePlaybookType: "Team Playbook",
          iTeamId:
            state && state?.iTeamId
              ? state?.iTeamId
              : sessionStorage.getItem("teamId"),
        };
      } else if (state && state.ePlaybookType === "My Playbook") {
        values = {
          ...values,
          ePlaybookType: "My Playbook",
        };
      }
      if (Object.keys(errors).length === 0) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/playbook/createTeamPlayBook`,
          values
        );
        let { data } = res;
        if (data && data.code === "201") {
          sessionStorage.setItem("iPlaybookId", data.data.iPlaybookId);
          setIsClear(true);
          setIsData(true);
          toast.current.show({
            severity: "success",
            summary: "Play Saved",
            life: 2000,
          });
          setTimeout(() => {
            window.$("#add-player-canvas").modal("hide");
          }, 2000);
          navigate("/whiteboard", {
            state: {
              ...state,
              iPlaybookId: data.data.iPlaybookId,
              eType: data.data.eType,
              sportData: {
                ...state.sportData,
              },
            },
          });
        }
      }
    },
    [
      pxToPercentage,
      setIsData,
      navigate,
      list,
      selectedSportGround,
      currentParentId,
      state,
    ]
  );

  const updateWhiteboardPlay = React.useCallback(
    async (event) => {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const newList = pxToPercentage(list);
          let values = {
            iPlaybookId:
              state && state.iPlaybookId
                ? state.iPlaybookId
                : sessionStorage.getItem("iPlaybookId"),
            vWhiteBoardDraw: JSON.stringify(newList),
            ePlaybookType:
              state && state.ePlaybookType ? state.ePlaybookType : "",
          };
          dispatch(UpdatePlay(values));
          dispatch(
            GetPlayById({
              iPlaybookId:
                state && state.iPlaybookId
                  ? state.iPlaybookId
                  : sessionStorage.getItem("iPlaybookId"),
            })
          );

          // setIsData(true);
        },
        reject: () => {},
      });
    },
    [list, state, dispatch, pxToPercentage]
  );

  // back action
  const handleBack = React.useCallback(() => {
    contextFreeDrawRef.current.clearRect(
      0,
      0,
      canvasFreeDrawRef.current.width,
      canvasFreeDrawRef.current.height
    );
    setList([]);
    dispatch({ type: CANVAS_BY_ID_SUCCESS, payload: {} });
    if ((state, state?.ePlaybookType && state?.backUrl !== "team-playbook")) {
      if (state) {
        if (state && state.ePlaybookType === "Team Playbook") {
          dispatch({ type: PLAYBOOK_ACTIVE_TAB_INDEX, payload: 1 });
        } else if (state && state.ePlaybookType === "Shared Playbook") {
          dispatch({ type: PLAYBOOK_ACTIVE_TAB_INDEX, payload: 2 });
        } else {
          dispatch({ type: PLAYBOOK_ACTIVE_TAB_INDEX, payload: 0 });
        }
      }
      navigate("/my-playbook", {
        state: { iTeamId: sessionStorage.getItem("teamId") },
      });
    } else {
      navigate("/team-playbook", {
        state: { iTeamId: sessionStorage.getItem("teamId") },
      });
    }
  }, [navigate, dispatch, canvasFreeDrawRef, contextFreeDrawRef, state]);

  const handleUndo = React.useCallback(() => {
    if (list && list.length > 0) {
      let newList = list;
      const data = newList.pop();
      setPoppedList([...poppedList, data]);
      setList(newList);
      reDraw();
    }
  }, [list, poppedList, reDraw]);

  const handleRedo = React.useCallback(() => {
    if (poppedList && poppedList.length > 0) {
      const prevLise = list;
      prevLise.push(poppedList[poppedList.length - 1]);
      poppedList.pop();
      setPoppedList(poppedList);
      setList(prevLise);
      reDraw();
    }
  }, [list, poppedList, reDraw]);

  const handleChangeColor = React.useCallback(
    (e) => {
      setFreeDrawColor(e.target.value);
      contextFreeDrawRef.current.strokeStyle = e.target.value;
      // contextFreeDraw.lineCap = "round";
      contextFreeDrawRef.current.fillStyle = e.target.value;
    },
    [contextFreeDrawRef]
  );

  const handleFolderClick = React.useCallback(
    (id) => {
      setCurrentParentId(id);
      if (
        state &&
        state.ePlaybookType &&
        (state.ePlaybookType === "Team Playbook" ||
          state.ePlaybookType === "My Playbook")
      ) {
        dispatch(
          GetAllFolderList({
            ePlaybookType: state.ePlaybookType,
            iTeamIds: [state?.iTeamId],
            iUserId: sessionStorage.getItem("id"),
            iParentId: id || 0,
          })
        );
      } else {
        dispatch(
          GetAllFolderList({
            iUserId: sessionStorage.getItem("id"),
            iTeamIds: [sessionStorage.getItem("teamId")],
            iParentId: id,
            ePlaybookType: "Team Playbook",
          })
        );
      }
    },
    [dispatch, state]
  );

  const handleAddFolder = React.useCallback(
    async (event) => {
      event.preventDefault();
      if (folderTitle.length > 0) {
        const newList = folderList;
        let req_data = {
          vPlayBook: folderTitle,
          iParentId: currentParentId,
          iSportId: state && state?.iSportId ? state.iSportId : "0",
          iTeamId: state && state?.iTeamId ? state.iTeamId : "0",
          eType: "Folder",
          iUserId: sessionStorage.getItem("id"),
          eAccess: "Editable",
          iSport_groundImageId: selectedSportGround,
          ePlaybookType: state && state.ePlaybookType,
        };

        // newList.push(req_data);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/playbook/createTeamPlayBook`,
            req_data
          );

          if (res && res.data && res.data.code === "201" && res.data.data) {
            newList.push({
              ...req_data,
              iPlaybookId: res.data.data.iPlaybookId,
            });
            setFolderTitle("");
            toast.current.show({
              severity: "success",
              summary: res.data?.message,
              life: 2000,
            });
            window.$("#add-folder").modal("hide");
            window.$("#add-player-canvas").modal("show");
          }
        } catch (error) {
          console.log(error);
        }
        setFolderList(newList);
        setFolderError(false);
      } else {
        setFolderError(true);
      }
    },
    [folderTitle, folderList, currentParentId, state, selectedSportGround]
  );

  const handleFolderList = React.useCallback(() => {
    if (
      state &&
      state.ePlaybookType &&
      (state.ePlaybookType === "Team Playbook" ||
        state.ePlaybookType === "My Playbook")
    ) {
      dispatch(
        GetAllFolderList({
          ePlaybookType: state.ePlaybookType,
          iTeamIds: [state?.iTeamId] || [sessionStorage.getItem("teamId")],
          iUserId: sessionStorage.getItem("id"),
          iParentId: state.currentParentId || "0",
        })
      );
    }
  }, [dispatch, state]);

  return (
    <>
      <Toast ref={toast} />
      <div>
        {/* header */}
        <div className="head-bg cst">
          <div className="head-bg-devide new-head">
            <Link className="navbar-brand profile-logo" to={"/profile"}>
              <img
                src={"../assets/img/symbol-logo.png"}
                className="img-fluid"
                alt=""
              />
            </Link>
            <button
              type="submit"
              onClick={handleBack}
              className="btn custom-back-btn"
            >
              <i
                className="fas fa-arrow-alt-left"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Back to Previous page"
              ></i>
            </button>
          </div>

          <div className="head-bg-devide">
            <div className="custom-navbar" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="head-signout" to={"/logout"}>
                    <div className="sign-out-btn">
                      <span>
                        {userProfileData && userProfileData?.data.vFirstName
                          ? `${userProfileData?.data.vFirstName?.charAt(
                              0
                            )}${userProfileData?.data.vLastName?.charAt(0)}`
                          : sessionStorage
                              .getItem("userName")
                              .split(" ")
                              .map((n, i, a) =>
                                i === 0 || i + 1 === a.length ? n[0] : null
                              )
                              .join("")
                              .toUpperCase()}
                      </span>
                      <div className="sign-name">
                        <p>
                          <i className="fas fa-sign-out"></i>
                        </p>
                        <p>
                          {userProfileData && userProfileData?.data.vFirstName
                            ? `${userProfileData?.data.vFirstName} ${userProfileData?.data.vLastName}`
                            : sessionStorage.getItem("userName")}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="left-panel-content space-con justify-content-around">
          {/* Actions */}
          <ul className="head-bg-bottom new-design center-head-bg-bottom">
            <li className="w-50">
              <input
                type="color"
                name="vColor"
                value={freeDrawColor}
                onChange={(e) => handleChangeColor(e)}
                className="form-control"
                style={{ width: "150px" }}
              />
            </li>
            <li onClick={handleUndo}>
              <i className="fas fa-undo"></i>
              <label>Undo</label>
            </li>
            <li onClick={handleRedo}>
              <i className="fas fa-redo"></i>
              <label>Redo</label>
            </li>
            {isErase ? (
              <>
                <li>
                  <i className="fas fa-eraser"></i>
                  <label>{eraseSize}x</label>
                </li>
                <li
                  onClick={() => {
                    setIsErase(false);
                    handleEraseStop();
                  }}
                >
                  <i className="fas fa-times"></i>
                  <label>Exit Erase</label>
                </li>
              </>
            ) : (
              <li className="defence">
                <div className="select-player formation-dropdown">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fas fa-eraser">
                        <span className="formation-value"></span>
                      </i>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={() => {
                          setIsErase(true);
                          handleErase(1);
                        }}
                      >
                        1x
                      </li>
                      <li
                        onClick={() => {
                          setIsErase(true);
                          handleErase(5);
                        }}
                      >
                        5x
                      </li>
                      <li
                        onClick={() => {
                          setIsErase(true);
                          handleErase(10);
                        }}
                      >
                        10x
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      {/* Canvas */}
      <div className="row right-panel">
        <div className="col-lg-12">
          <div className="team-coach-body">
            {/* Canvas  */}
            <div
              className="add-canvas m-auto"
              style={{
                width: `${dimentions.width}px`,
                height: `${dimentions.height}px`,
                // border: `1px solid red`,
              }}
              onClick={handleCanvasClick}
            >
              {/* free draw canvas */}
              {/* {imagePath} */}
              <img
                className="playground-img"
                style={{
                  width: `${dimentions.width}px`,
                  height: `${dimentions.height}px`,
                }}
                src={imagePath ? `${imagePath}` : "assets/gif/BasFoot.gif"}
                // src={"assets/img/soccer_field.jpg"}
                alt=""
                id="play"
              ></img>
              <canvas
                // className={isFreeDraw ? "free-draw-canvas " : "canvas"}
                className={"free-draw-canvas "}
                id="canvas"
                ref={canvasFreeDrawRef}
                style={{
                  width: `${dimentions.width}px`,
                  height: `${dimentions.height}px`,
                }}
                onMouseOut={handleFinishDraw}
                onMouseDown={handleStartDraw}
                onMouseUp={handleFinishDraw}
                onMouseMove={handleDraw}
              />
            </div>

            {/* Bottom  */}
          </div>
        </div>
      </div>
      <div
        className="custombtn-inground"
        style={{
          width: `${dimentions.width}px`,
        }}
      >
        {state && state.iPlaybookId && state.iPlaybookId > 0 && state?.eType ? (
          <button
            type="button"
            onClick={updateWhiteboardPlay}
            className="btn btn-primary me-2"
          >
            Update
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              handleFolderList();
              window.$("#add-player-canvas").modal("show");
            }}
            className="btn btn-primary me-2"
          >
            Save
          </button>
        )}

        <DropDown
          title={"Draw Type"}
          name={"freeDrawType"}
          field={"label"}
          fieldId={"id"}
          data={freeDrawTypeData}
          isValidate={false}
          isSetValue={false}
          // filter={true}
          placeHolder={"Draw Type"}
          setConditionValue={setFreeDrawType}
        />
      </div>

      <div
        className="modal fade"
        id="add-player-canvas"
        tabIndex="-1"
        aria-labelledby="edit-player"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable edit-player-model">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="edit-player">
                {sessionStorage.getItem("iPlaybookId")
                  ? "Edit Whitebook"
                  : "Save Whitebook"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={handleClose}
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="my-canvas-listing">
                    <div className="row">
                      <div className="col-md-12 col-lg-12  me-auto">
                        <div className="d-sm-flex  align-items-center mb-4 flex-wrap">
                          <div
                            className="d-flex align-items-center"
                            style={{ marginRight: "2rem" }}
                          >
                            <label
                              type="button"
                              // onClick={() => {
                              //   window.$("#add-folder").modal("show");
                              // }}
                              // onClick={() => setIsAddFolder(true)}
                              data-bs-target="#add-folder"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              className="btn btn-primary submit-form"
                            >
                              Create Folder
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" folder-structure">
                        <div className="canvas-listing d-flex">
                          {hierarchy && hierarchy.length > 0 ? (
                            hierarchy.map((item, key) => (
                              <div className="breadcrump-list " key={key}>
                                {key === 0 ? (
                                  <span onClick={() => handleFolderClick("0")}>
                                    <i className="fas fa-home"></i>
                                    <span className="name">
                                      {item.vPlayBook}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="breadcrump-list-sub">
                                    <i className="fas fa-arrow-right"></i>
                                    <div
                                      onClick={() =>
                                        handleFolderClick(item.iPlaybookId)
                                      }
                                      className="name"
                                    >
                                      {item.vPlayBook}
                                    </div>
                                  </span>
                                )}
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="canvas-listing folder-name">
                          {folderList && folderList.length > 0 ? (
                            folderList.map((item, key) =>
                              item.eType === "Folder" ? (
                                <React.Fragment key={key}>
                                  <div
                                    key={key}
                                    className="folder-list"
                                    onDoubleClick={() =>
                                      handleFolderClick(item.iPlaybookId)
                                    }
                                  >
                                    <div className="folder-file-icon">
                                      {item.eType === "Whiteboard" ? (
                                        <i className="fas fa-chalkboard"></i>
                                      ) : item.eType === "Folder" ? (
                                        <i className="fas fa-folder"></i>
                                      ) : (
                                        <i className="far fa-file"></i>
                                      )}
                                    </div>
                                    <span className="name-file">
                                      {item.vPlayBook}
                                    </span>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={key}></React.Fragment>
                              )
                            )
                          ) : (
                            <>
                              <div className="" style={{ color: "#dc3545" }}>
                                <i className="far fa-folder-open mx-2"></i>
                                Folder Is Empty
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSave}>
                <div className="row g-lg-4 g-3 ">
                  <div className="col-lg-12">
                    Play Name:
                    <Input
                      name="vPlayBook"
                      title="PlayName"
                      type={"text"}
                      isError={false}
                      placeHolder={"PlayName"}
                      isSetValue={false}
                      isValidate={true}
                      isClear={isClear}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="submit-form-model">
                    <>
                      <button
                        type="button"
                        className="cancel--model"
                        data-bs-dismiss="modal"
                        // onClick={handleClose}
                      >
                        Close
                      </button>

                      <button
                        type="submit"
                        // disabled={CheckPermission(5) ? false : true}
                        id="save"
                        className="submi--btn"
                      >
                        Save
                      </button>
                    </>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* add folder pop popup */}
      <div
        className="modal fade"
        id="add-folder"
        tabindex="-1"
        role="dialog"
        aria-labelledby="add-folderLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered edit-player-model"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add-folderLabel">
                Add Folder
              </h5>
              <button
                type="button"
                className="btn-close"
                // data-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  window.$("#add-folder").modal("hide");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form method="post" onSubmit={handleAddFolder}>
                <div className="">
                  <label>Folder name</label>
                  <input
                    type="text"
                    placeholder="Enter folder name"
                    name="vFolderTitle"
                    className="form-control"
                    id="vFolderTitle"
                    value={folderTitle}
                    onChange={(e) => setFolderTitle(e.target.value)}
                  />
                  {folderError ? (
                    <p className="error">Folder Name Is Required.</p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary submit-form" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* end create folder popup */}
      {/* <div
        className="modal fade"
        id="add-play-canvas"
        tabIndex="-1"
        aria-labelledby="add-play"
        aria-hidden="true"
      > */}
      {/* <Toast ref={toast} /> */}
      {/* <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable = add-play-model">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add-play">
                Add Play
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={handleSave}>
                <div className="row g-lg-4 g-3 ">
                  <div className="col-lg-12">
                    <Input
                      name="vPlayBook"
                      title="PlayName"
                      type={"text"}
                      isError={false}
                      placeHolder={"PlayName"}
                      isSetValue={false}
                      isValidate={true}
                      // isClear={isClear}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="submit-form-model">
                    <>
                      <button
                        type="button"
                        className="cancel--model"
                        data-bs-dismiss="modal"
                        // onClick={handleClose}
                      >
                        Close
                      </button>
                      <button type="submit" id="save" className="submi--btn">
                        Save changes
                      </button>
                    </>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
}

export default WhiteBoard;
