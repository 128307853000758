export const EVENT_SCREENS = {
  teams: "teams",
  teams_area: "teams_area",
  create_team: "create_team",
  create_organization: "create_organization",
  user_details: "user_details",
  organization_details: "organization_details",
  organization_settings: "organization_settings",
  organization_details_about: "organization_details_about",
  team_post: "team_post",
  team_members: "team_members",
  team_followers: "team_followers",

  organization_calendar: "organization_calendar",
  submit_attendance: "submit_attendance",
  create_event: "create_event",
  create_practice: "create_practice",
  create_game: "create_game",

  team_playbook: "team_playbook",
  team_videos: "team_videos",
  add_coach: "add_coach",
  edit_coach: "edit_coach",
  add_player: "add_player",
  edit_player: "edit_player",

  team_calendar: "team_calendar",
  submit_attendance_team: "submit_attendance",
  create_event_team: "create_event",
  create_practice_team: "create_practice",
  create_game_team: "create_game",

  notification: "notification",

  chat_listing: "chat_listing",
  chat: "chat",
  chat_media: "chat_media",

  group_feed: "group_feed",
  group_feed_comment: "group_feed_comment",
  group_feed_report: "group_feed_report",
  create_group_post: "create_group_post",
  create_group: "create_group",
  all_groups: "all_groups",
  group_details: "group_details",
  group_members: "group_members",
  add_member: "add_member",
  group_calendar: "group_calendar",
  create_group_event: "create_group_event",
  group_settings: "group_settings",
  banned_members: "banned_members",
  group_reported_post: "group_reported_post",

  my_plays: "my_plays",
  team_plays: "team_plays",

  my_play: "my_play",
  team_play: "team_play",

  my_whiteboard: "my_whiteboard",
  team_whiteboard: "team_whiteboard",

  my_folder: "my_folder",
  team_folder: "team_folder",

  personal_calendar: "personal_calendar",
  create_event_peronal: "create_event",
  update_event: "update_event",
  delete_event: "delete_event",

  message_board: "message_board",
  report_post: "report_post",
  message_board_comments: "message_board_comments",
  create_post: "create_post",
  all_members: "all_members",
  banned_members_msg_board: "banned_members",
  reported_posts: "reported_posts",

  social: "social",
  comment: "comment",
  report_post_social: "report_post",
  //   search: "search",
  create_post_social: "create_post",
  // share

  // burger_menu
  edit_bio: "edit_bio",
  // followers
  // view_profile
  // edit_profile

  settings: "settings",
  general_settings: "general_settings",
  change_password: "change_password",
  two_step_verification: "two_step_verification",
  delete_account: "delete_account",

  team_highlight: "team_highlight",

  followers: "followers",

  // team_post
  // team_about
  // team_members
  // team_followers

  general_all_search: "general_all_search",
  general_post_search: "general_post_search",
  general_people_search: "general_people_search",
  general_teams_search: "general_teams_search",

  thank_you:"thank_you"
};
