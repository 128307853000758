import React, { useCallback, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useLocation } from "react-router-dom";
import {
  // GetAllSubTeamByParentID,
  GetAllTeamData,
  GetAllTeams,
  // GetTeamById,
} from "../../../store/action/teamAction";
import {
  LogoutUser,
  // ResetState,
  UserProfile,
  getCountries,
} from "../../../store/action/userAction";
import { getTeamTypes } from "../../../store/action/userAction";
import { SetCurrentPath } from "../../../store/action/headerAction";
import { GetCategorywiseSubCat } from "../../../store/action/homeAction";
import { GetProfileListData } from "../../../store/action/profileListAction";
import PropsTypes from "prop-types";
import { GetAllSearchResults } from "../../../store/action/searchAction";
// import { GetAllPost, GetAllPostByUser } from "../../../store/action/postAction";
import { USER_LOGOUT } from "../../../store/constants/logout";
// import { GetAllTeamFollowers } from "../../../store/action/followAction";
// import DropDown from "../DropDown/DropDown";
// import { Dialog } from "primereact/dialog";
// import { SetCountryDialog } from "../../../store/action/otherAction";
import { useGetUnder13User } from "../../../hooks/useGetUnder13User";

// SVG
import TeamManagementSVG from "./LeftList/TeamManagementSVG";
// import MarketSVG from "./LeftList/MarketSVG";
// import StatsSVG from "./LeftList/StatsSVG";
import CalendarSVG from "./LeftList/CalendarSVG";
import FollowersSVG from "./LeftList/FollowersSVG";
import SettingsSVG from "./LeftList/SettingsSVG";
import TeamPlaybookSVG from "./LeftList/TeamPlaybookSVG";
import TeamMSGBoardSVG from "./LeftList/TeamMSGBoardSVG";
import {
  GetAllNotificationList,
  GetGroupNotificationCount,
  GetMessageBoardNotificationCount,
  GetNotificationCount,
} from "../../../store/action/notificationsAction";
import { useDayChecker } from "../../../hooks/useDayChecker";
import NotificationFollowButtons from "./TopBanner/NotificationFollowButtons";
import axios from "axios";
import NotificationUserInviteButtons from "./TopBanner/NotificationUserInviteButtons";
import { VIEW_MORET_TIME_POST_ID } from "../../../store/constants/post";
// import NotificationEventDetail from "./NotificationEventDetail";
import { SetEventNotificationDetails } from "../../../store/action/otherAction";
import { GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS } from "../../../store/constants/notifications";
import { SELECTED_GROUP_FOR_FEED } from "../../../store/constants/group";
import GroupsSVG from "./LeftList/GroupsSVG";
import { EVENT_SCREENS } from "../../../configue/firebase/EventConstants";
import FirebaseLogEvents from "../../../configue/firebase/LogEvents";

function AuthHeader({
  isMenuClose,
  isNotificationClose,
  setIsNotificationClose,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isUnder13 = useGetUnder13User();
  const {
    getAllNotificationsData,
    getNotificationCountData,
    getMessageBoardNotificationCountData,
    getGroupNotificationCountData,
  } = useSelector((state) => state.notifications);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);

  // const [showModel, setShowModel] = React.useState(false);

  const notificationRef = useRef(null);

  React.useEffect(() => {
    if (isNotificationOpen) {
      console.log("isNotificationOpen", isNotificationOpen);
      FirebaseLogEvents("notification", EVENT_SCREENS.notification);
    }
  }, [isNotificationOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        isNotificationOpen
        // &&
        // !event.target.closest('button')
      ) {
        // setTimeout(() => {
        setIsNotificationOpen(false);
        // }, 100);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isNotificationOpen]);

  const handleBellIconClick = (e) => {
    e.stopPropagation();
    setIsNotificationOpen((prev) => !prev);
  };

  const queryParams = new URLSearchParams(location.search);
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [isMenuOpen, setIsMenuOpen] = React.useState({ id: 0, type: false });
  const [currentUser, setCurrentUser] = React.useState({
    iUserId: 0,
    vName: "",
    vImage: "",
    isSet: false,
  });
  // const [isClear, setIsClear] = React.useState(false);
  const [searchText, setSearchText] = React.useState(
    queryParams.get("q") !== null &&
      queryParams.get("q") !== undefined &&
      queryParams.get("q").length > 0
      ? queryParams.get("q")
      : ""
  );
  const { userProfileData, countries, teamTypes } = useSelector(
    (state) => state.user
  );
  const { allTeamsData } = useSelector((state) => state.team);
  const { getCategorywiseSubCategoryData } = useSelector((state) => state.home);
  const { getProfileListData } = useSelector((state) => state.profileList);
  // const { countryDialogData } = useSelector((state) => state.others);
  // const [visible, setVisible] = React.useState(false);

  // const openDialog = () => setVisible(true);

  React.useEffect(() => {
    if (!teamTypes) {
      dispatch(getTeamTypes());
    }
  }, [teamTypes, dispatch]);

  React.useEffect(() => {
    if (
      !currentUser.isSet &&
      getProfileListData &&
      getProfileListData.code === "200" &&
      getProfileListData.data
    ) {
      if (
        sessionStorage.getItem("isParent") === "Yes" &&
        getProfileListData.data.ChildTeamMember &&
        getProfileListData.data.ChildTeamMember.length > 0
      ) {
        const childData = getProfileListData.data.ChildTeamMember.find(
          (item) => item.iUserId.toString() === sessionStorage.getItem("id")
        );
        if (childData && Object.keys(childData).length > 0) {
          setCurrentUser({
            iUserId: childData.user.iUserId,
            vName: `${
              childData.user?.vFirstName &&
              childData.user?.vFirstName.length > 0
                ? childData.user?.vFirstName.charAt(0)
                : ""
            }${
              childData.user?.vLastName && childData.user?.vLastName.length > 0
                ? childData.user?.vLastName.charAt(0)
                : ""
            }`,
            vImage: childData.user?.vImage,
            isSet: true,
          });
        }
      } else {
        if (
          getProfileListData.data.userData &&
          getProfileListData.data.userData.iUserId.toString() ===
            sessionStorage.getItem("id")
        ) {
          setCurrentUser({
            iUserId: getProfileListData.data.userData.iUserId,
            vName: `${
              getProfileListData.data.userData?.vFirstName &&
              getProfileListData.data.userData?.vFirstName.length > 0
                ? getProfileListData.data.userData?.vFirstName.charAt(0)
                : ""
            }${
              getProfileListData.data.userData?.vLastName &&
              getProfileListData.data.userData?.vLastName.length > 0
                ? getProfileListData.data.userData?.vLastName.charAt(0)
                : ""
            }`,
            vImage: getProfileListData.data.userData?.vImage,
            isSet: true,
          });
        }
      }
    }
  }, [getProfileListData, currentUser]);

  // React.useEffect(() => {
  //   if (isMenuClose && !isMenuClose.type && isMenuClose.id === 0) {
  //     setIsMenuOpen({ id: 0, type: false });
  //   }
  // }, [isMenuClose]);

  React.useEffect(() => {
    if (!getCategorywiseSubCategoryData) {
      dispatch(GetCategorywiseSubCat());
    }
  }, [dispatch, getCategorywiseSubCategoryData]);

  React.useEffect(() => {
    if (sessionStorage.getItem("isParent") === "Yes") {
      dispatch(
        GetProfileListData({ iUserId: sessionStorage.getItem("parentid") })
      );
      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });
    } else {
      dispatch(GetProfileListData({ iUserId: sessionStorage.getItem("id") }));
      setCurrentUser({
        iUserId: 0,
        vName: "",
        vImage: "",
        isSet: false,
      });
    }
  }, [dispatch]);

  const handleSearch = React.useCallback(
    (e) => {
      e.preventDefault();
      if (searchText?.length > 0) {
        // setIsOpen(true);
        dispatch(
          GetAllSearchResults({
            iUserId: sessionStorage.getItem("id"),
            searchName: searchText,
          })
        );
        navigate(`/search-filter?q=${searchText ? searchText : ""}`);
      } else {
        return null;
      }
    },
    [navigate, searchText, dispatch]
  );

  React.useEffect(() => {
    if (location.pathname !== "add-new-canvas") {
      sessionStorage.setItem("iPlaybookId", "");
    }
  }, [location]);

  React.useEffect(() => {
    const values = {
      iUserId: sessionStorage.getItem("id"),
    };
    dispatch(UserProfile(values));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(GetAllTeamData({ iUserId: userProfileData?.data?.iUserId }));
  }, [dispatch, userProfileData]);

  React.useEffect(() => {
    if (!allTeamsData) {
      dispatch(GetAllTeams());
    }
  }, [allTeamsData, dispatch]);

  React.useEffect(() => {
    if (!countries) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  React.useEffect(() => {
    if (location.pathname.search("business") > 0) {
      dispatch(SetCurrentPath("/business-profile"));
    } else {
      dispatch(SetCurrentPath("/profile"));
    }
  }, [location.pathname, dispatch]);

  // React.useEffect(() => {
  //   console.log("isNotificationClose && isNotificationOpen", isNotificationClose , isNotificationOpen);
  //   if (isNotificationClose && isNotificationOpen) {
  //     setIsNotificationOpen(false);
  //     setIsNotificationClose(false);
  //   }
  // }, [isNotificationClose, setIsNotificationClose, isNotificationOpen]);

  // const handleTeam = React.useCallback(
  //   (id) => {
  //     sessionStorage.setItem("teamId", id);

  //     if (location.pathname === "/teams-details") {
  //       const values = {
  //         iTeamId: id,
  //         iUserId: sessionStorage.getItem("id"),
  //       };

  //       if (parseInt(values.iUserId) > 0 && parseInt(values.iTeamId) > 0) {
  //         dispatch(GetTeamById(values));
  //       }

  //       dispatch(
  //         GetAllTeamFollowers({
  //           iUserId: sessionStorage.getItem("id"),
  //           iTeamId: id,
  //         })
  //       );

  //       dispatch(
  //         GetAllSubTeamByParentID({
  //           iParentId: id,
  //         })
  //       );
  //       dispatch(GetAllTeamData());
  //     } else {
  //       navigate("/teams-details", { state: { iTeamId: id } });
  //     }
  //   },
  //   [navigate, location, dispatch]
  // );

  // const handleNavigate = React.useCallback(
  //   (id) => {
  //     navigate("/business-profile", { state: { iBussinesTranId: id } });
  //   },
  //   [navigate]
  // );

  // const handleChildProfile = React.useCallback(
  //   (childId, parentId, data) => {
  //     if (
  //       sessionStorage.getItem("parentid") &&
  //       sessionStorage.getItem("parentid").length > 0
  //     ) {
  //       sessionStorage.setItem("parentid", sessionStorage.getItem("parentid"));
  //     } else {
  //       sessionStorage.setItem("parentid", parentId);
  //     }
  //     sessionStorage.setItem("id", childId);
  //     sessionStorage.setItem("isParent", "Yes");
  //     dispatch(ResetState());
  //     setCurrentUser({
  //       iUserId: 0,
  //       vName: "",
  //       vImage: "",
  //       isSet: false,
  //     });
  //     navigate("/profile");
  //   },
  //   [navigate, userProfileData, dispatch]
  // );

  // const handleParentProfile = React.useCallback(
  //   (data) => {
  //     dispatch(
  //       GetAllPostByUser({ iUserId: data.iUserId, iFriendUserId: data.iUserId })
  //     );
  //     dispatch(GetAllPost({ iUserId: data.iUserId, page: 1 }));

  //     if (data && Object.keys(data).length > 0) {
  //       dispatch(ResetState());
  //       sessionStorage.setItem("id", data.iUserId);
  //       sessionStorage.setItem("isParent", "No");
  //       setCurrentUser({
  //         iUserId: 0,
  //         vName: "",
  //         vImage: "",
  //         isSet: false,
  //       });
  //       navigate("/profile");
  //     }
  //   },
  //   [navigate, dispatch]
  // );

  // const handleCloseMenu = () => {
  //   setIsMenuOpen({ id: 0, type: false });
  // };

  const handleNotificationMove = React.useCallback(
    (item) => {
      const logInUserId = sessionStorage.getItem("id");
      if (item.iUserId.toString() === logInUserId) {
        navigate("/profile");
      } else {
        navigate(`/auth/${item.user.vPersonalSlug}`);
      }
    },
    [navigate]
  );

  const handlePostClick = React.useCallback(
    (item) => {
      dispatch({
        type: VIEW_MORET_TIME_POST_ID,
        payload: {
          isShowModal: true,
          POST_ID: item.iPostId,
          renderedApiTabName: "",
        },
      });
      window.$("#commentbox").modal("show");
    },
    [dispatch]
  );

  const handleEventClick = React.useCallback(
    (item) => {
      if (Object.keys(item).length > 0 && item.calendar) {
        dispatch(SetEventNotificationDetails(item.calendar));
        window.$("#notificationEventDetails").modal("show");
      }
    },
    [dispatch]
  );

  const NotificationBlock = ({ item }) => {
    // console.log("++++ NotificationBlock", item);
    return (
      <div className="box">
        <div className="image-box">
          {item.user && Object.keys(item.user).length > 0 ? (
            <img
              className="set-cursor-pointer"
              onClick={
                () => handleNotificationMove(item)

                // navigate(`/auth/${item.user.vPersonalSlug}`);
              }
              src={
                item.user.vImage && item.user.vImage.split("/").pop() !== null
                  ? item.user.vImage
                  : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
              }}
            />
          ) : item.team && Object.keys(item.team).length > 0 ? (
            <img
              className="set-cursor-pointer"
              onClick={() => {
                sessionStorage.setItem("teamId", item.team.iParentId);
                navigate(`/team/auth/${item.team.vTeamSlug}`, {
                  state: {
                    iTeamId: item.team.iParentId,
                  },
                });
              }}
              src={
                item.team.vTeamLogo &&
                item.team.vTeamLogo.split("/").pop() !== null
                  ? item.team.vTeamLogo
                  : `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="notifi-msg">
          <div className="msg-p">
            {item.eNotificationType === "teamInvite" ? (
              <>
                <span
                  onClick={
                    item.eNotificationType === "post"
                      ? () => handlePostClick(item)
                      : () => {}
                  }
                  className={
                    item.eNotificationType === "post"
                      ? "msg-text set-cursor-pointer"
                      : ""
                  }
                >
                  {item.tMessage}
                </span>
                <span
                  className="user-name set-cursor-pointer"
                  onClick={
                    item.user && Object.keys(item.user).length > 0
                      ? () => {
                          handleNotificationMove(item);
                          // navigate(`/auth/${item.user.vPersonalSlug}`);
                        }
                      : item.team && Object.keys(item.team).length > 0
                      ? () => {
                          sessionStorage.setItem("teamId", item.team.iParentId);
                          navigate(`/team/auth/${item.team.vTeamSlug}`, {
                            state: {
                              iTeamId: item.team?.iParentId,
                            },
                          });
                        }
                      : () => {}
                  }
                >
                  {item.user && Object.keys(item.user).length > 0
                    ? `${item.user.vFirstName} ${
                        item.user.vLastName && item.user.vLastName.length > 0
                          ? item.user.vLastName
                          : ""
                      } `
                    : item.team && Object.keys(item.team).length > 0
                    ? item.team.tTeamTagline
                    : ""}
                </span>
              </>
            ) : (
              <>
                <span
                  className="user-name set-cursor-pointer"
                  onClick={
                    item.user && Object.keys(item.user).length > 0
                      ? () => {
                          handleNotificationMove(item);
                          // navigate(`/auth/${item.user.vPersonalSlug}`);
                        }
                      : item.team && Object.keys(item.team).length > 0
                      ? () => {
                          sessionStorage.setItem("teamId", item.team.iParentId);
                          navigate(`/team/auth/${item.team.vTeamSlug}`, {
                            state: {
                              iTeamId: item.team?.iParentId,
                            },
                          });
                        }
                      : () => {}
                  }
                >
                  {item.user && Object.keys(item.user).length > 0
                    ? `${item.user.vFirstName} ${
                        item.user.vLastName && item.user.vLastName.length > 0
                          ? item.user.vLastName
                          : ""
                      } `
                    : item.team && Object.keys(item.team).length > 0
                    ? item.team.tTeamTagline
                    : ""}
                </span>
                <span
                  onClick={
                    item.eNotificationType === "post"
                      ? () => handlePostClick(item)
                      : item.eNotificationType === "event"
                      ? () => handleEventClick(item)
                      : () => {}
                  }
                  className={
                    item.eNotificationType === "post" ||
                    item.eNotificationType === "event"
                      ? "msg-text set-cursor-pointer"
                      : ""
                  }
                >
                  {item.tMessage}
                </span>
              </>
            )}

            <span className="notifi-time">
              {useDayChecker(item.dtAddedDate)} ago
            </span>
          </div>
          {item.user &&
          Object.keys(item.user).length > 0 &&
          item.eNotificationType === "follow" ? (
            <NotificationFollowButtons item={item} />
          ) : (
            <></>
          )}
          {item.eNotificationType === "teamInvite" &&
          item.vAuthCode !== null &&
          item.iRoleId !== null ? (
            <NotificationUserInviteButtons item={item} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleNotificationSeen = React.useCallback(async () => {
    // dispatch(SetNotificationSeen({ iVisibleId: sessionStorage.getItem("id") }));
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/seenNotification`,
      { iVisibleId: sessionStorage.getItem("id") }
    );
    if (resData.status === 200 && resData.data && resData.data.code === "200") {
      dispatch(
        GetAllNotificationList({ iVisibleId: sessionStorage.getItem("id") })
      );
      dispatch(
        GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
      );
      dispatch(
        GetMessageBoardNotificationCount({
          iVisibleId: sessionStorage.getItem("id"),
        })
      );
      dispatch({
        type: GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS,
        // payload: {
        //   status: "success",
        //   code: "200",
        //   message: "Record Not Found",
        //   data: null,
        // },
        payload: {},
      });
    }
    setIsNotificationOpen(!isNotificationOpen);
  }, [dispatch, isNotificationOpen]);

  const handleLogout = React.useCallback(() => {
    confirmDialog({
      message: "Are You Sure You Want To Logout?",
      className: "logout-popup",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        dispatch(LogoutUser());
        dispatch({ type: USER_LOGOUT });
        navigate("/logout");
      },
      reject: () => {},
    });
  }, [navigate, dispatch]);

  const handleMessageBoardNotificationSeen = React.useCallback(async () => {
    // dispatch(SetNotificationSeen({ iVisibleId: sessionStorage.getItem("id") }));
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/seenMessageBoardNotificationData`,
      { iVisibleId: sessionStorage.getItem("id") }
    );
    if (resData.status === 200 && +resData.data.code === +0) {
      dispatch(
        GetMessageBoardNotificationCount({
          iVisibleId: sessionStorage.getItem("id"),
        })
      );
      dispatch(
        GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
      );
    }
  }, [dispatch]);

  const handleMessageBoardClick = useCallback(() => {
    handleMessageBoardNotificationSeen();
    if (location.pathname !== "/message-board-posts") {
      navigate("/message-board-posts");
    }
  }, [handleMessageBoardNotificationSeen, location?.pathname, navigate]);

  useEffect(() => {
    if (location.pathname === "/message-board-posts") {
      handleMessageBoardNotificationSeen();
    }
  }, [handleMessageBoardNotificationSeen, location?.pathname]);

  const handleGroupNotificationSeen = React.useCallback(async () => {
    // dispatch(SetNotificationSeen({ iVisibleId: sessionStorage.getItem("id") }));
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/notification/seenGroupNotificationData`,
      { iVisibleId: sessionStorage.getItem("id") }
    );
    if (+resData.data.code === +200) {
      dispatch(
        GetGroupNotificationCount({
          iVisibleId: sessionStorage.getItem("id"),
        })
      );
      dispatch(
        GetNotificationCount({ iVisibleId: sessionStorage.getItem("id") })
      );
    }
  }, [dispatch]);

  const handleGroupClick = useCallback(() => {
    handleGroupNotificationSeen();
    if (location.pathname !== "/groups") {
      dispatch({
        type: SELECTED_GROUP_FOR_FEED,
        payload: {},
      });
      navigate("/groups");
    }
  }, [dispatch, handleGroupNotificationSeen, location.pathname, navigate]);

  useEffect(() => {
    if (location.pathname === "/groups") {
      handleGroupNotificationSeen();
    }
  }, [handleGroupNotificationSeen, location?.pathname]);

  return (
    <>
      <div className="dash-header new-dash-header">
        <div className="right-header">
          {isUnder13 ? (
            <></>
          ) : (
            <>
              {/* <Link
                to={"/select-sport"}
                className="create-btn create-btn-center"
              >
                <i className="far fa-plus"></i>
                <span>Create Team</span>
              </Link>
              <Link to={"/create-organization"} className="create-btn ">
                <i className="far fa-plus"></i>
                <span>Create Organisation</span>
              </Link> */}
            </>
          )}
          <div className="login-top-header " style={{ justifyContent: "end" }}>
            <div className="top-header-right">
              <div
                // onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                className={
                  getNotificationCountData &&
                  getNotificationCountData.code === "200" &&
                  getNotificationCountData.data &&
                  getNotificationCountData.data.eSeenCount &&
                  getNotificationCountData.data.eSeenCount > 0
                    ? "notification-btn active"
                    : "notification-btn"
                }
              >
                {getNotificationCountData &&
                getNotificationCountData.code === "200" &&
                getNotificationCountData.data &&
                getNotificationCountData.data.eSeenCount ? (
                  getNotificationCountData.data.eSeenCount === 0 ? (
                    <></>
                  ) : (
                    <span className="notification-count">
                      {getNotificationCountData.data.eSeenCount}
                    </span>
                  )
                ) : (
                  <></>
                )}

                <img
                  className="no-notification"
                  src="/assets/img/system-outline-46-notification-bell.png"
                  // onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                  onClick={handleBellIconClick}
                  alt="notification"
                  style={{ filter: "invert(1)" }}
                />
                <img
                  className="in-notification"
                  src="/assets/img/system-outline-46-notification-bell.gif"
                  onClick={handleNotificationSeen}
                  alt="notification"
                  style={{ filter: "invert(1)" }}
                />
                {isNotificationOpen && (
                  <div className="notification-box" ref={notificationRef}>
                    <div className="title-top">
                      <h3>Notifications</h3>
                    </div>
                    <div className="inner-box">
                      {getAllNotificationsData &&
                      getAllNotificationsData.code === "200" &&
                      getAllNotificationsData.data &&
                      getAllNotificationsData.data.length > 0 ? (
                        getAllNotificationsData.data
                          .slice(0, 19)
                          .map((item, key) => (
                            <NotificationBlock item={item} key={key} />
                          ))
                      ) : (
                        <div className="box">
                          <div className="image-box">
                            <img
                              src={"/assets/img/loader.png"}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                            />
                          </div>
                          <div className="notifi-msg">
                            <div className="msg-p">
                              <span className="msg-text">
                                Welcome To Yo Playbook
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* {isNotificationOpen ? (
                  <div className="notification-box">
                    <div className="title-top">
                      <h3>Notifications</h3>
                    </div>
                    <div className="inner-box">
                      {getAllNotificationsData &&
                      getAllNotificationsData.code === "200" &&
                      getAllNotificationsData.data &&
                      getAllNotificationsData.data.length > 0 ? (
                        getAllNotificationsData.data
                          .slice(0, 19)
                          .map((item, key) => (
                            <NotificationBlock item={item} key={key} />
                          ))
                      ) : (
                        <div className="box">
                          <div className="image-box">
                            <img
                              src={"/assets/img/loader.png"}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_GET_PUBLIC_PATH}/admin/assets/images/no_image.png`;
                              }}
                            />
                          </div>
                          <div className="notifi-msg">
                            <div className="msg-p">
                              <span className="msg-text">
                                Welcome To Yo Playbook
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
          <div className="nav-item search-panel">
            <form onSubmit={handleSearch} method="post">
              <div className="form-group">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search..."
                />
                <div className="image-box search-icon">
                  <img
                    src="/assets/img/fi-rr-search.png"
                    alt="searchbar"
                    width="18"
                    height="18"
                    title="Searchbar"
                    onClick={handleSearch}
                  />
                </div>
              </div>
            </form>
          </div>
          <button className="create-btn border-0" onClick={handleLogout}>
            Sign Out
          </button>
        </div>
      </div>
      <div className="menu-header">
        <div className="inner-menu">
          <Link
            className={
              location.pathname === "/my-teams" ? "menu active" : "menu"
            }
            to={"/my-teams"}
          >
            <div className="image-icon">
              <TeamManagementSVG />
            </div>
            <p>Teams</p>
          </Link>
          {isUnder13 ? (
            <></>
          ) : (
            <>
              {/* <div className="menu set-cursor-pointer">
                <div className="image-icon set-cursor-pointer">
                  <MarketSVG />
                </div>
                <p>Market</p>
              </div> */}
              {/* <div
                className="menu set-cursor-pointer"
                // to={"/market-place"}
                // state={{ prevPath: location.pathname }}
                // onMouseEnter={() => setVisibleStats(+true)}
                // onMouseLeave={() => setVisibleStats(+false)}
              >
                <div className="image-icon set-cursor-pointer">
                  <StatsSVG />
                </div>
                <p>Stats</p>
              </div> */}
            </>
          )}
          <Link
            className={
              location.pathname === "/calendar" ? "menu active" : "menu"
            }
            to={"/calendar"}
          >
            <div className="image-icon">
              <CalendarSVG />
            </div>
            <p>My Calendar</p>
          </Link>
          <Link
            className={
              location.pathname === "/my-playbook" ? "menu active" : "menu"
            }
            to={"/my-playbook"}
          >
            <div className="image-icon">
              <TeamPlaybookSVG />
            </div>
            <p>My Playbook</p>
          </Link>
          <div
            className={
              location.pathname === "/message-board-posts"
                ? "menu active set-cursor-pointer"
                : "menu set-cursor-pointer"
            }
            onClick={handleMessageBoardClick}
          >
            <div
              className="image-icon"
              style={{
                position: "relative",
              }}
            >
              {getMessageBoardNotificationCountData &&
              getMessageBoardNotificationCountData.code === "200" &&
              getMessageBoardNotificationCountData.data &&
              getMessageBoardNotificationCountData.data.eSeenCount ? (
                getMessageBoardNotificationCountData.data.eSeenCount === 0 ? (
                  <></>
                ) : (
                  <span
                    className="notification-count"
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#ff0000",
                      position: "absolute",
                      top: "-3px",
                      right: "25px",
                      color: "#fff",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    {getMessageBoardNotificationCountData.data.eSeenCount}
                  </span>
                )
              ) : (
                <></>
              )}
              <TeamMSGBoardSVG />
            </div>
            <p>Message Board</p>
          </div>
          {isUnder13 ? (
            <></>
          ) : (
            <>
              {/* <Link
                className={
                  location.pathname === "/followers" ? "menu active" : "menu"
                }
                to={"/followers"}
              >
                <div className="image-icon">
                  <FollowersSVG />
                </div>
                <p>Followers</p>
              </Link> */}
              <div
                className={
                  location.pathname === "/groups"
                    ? "menu active set-cursor-pointer"
                    : "menu set-cursor-pointer"
                }
                // to={"/groups"}
                onClick={handleGroupClick}
                // onClick={dispatch({
                //   type: SELECTED_GROUP_FOR_FEED,
                //   payload: {},
                // })}
              >
                <div
                  className="image-icon"
                  style={{
                    position: "relative",
                  }}
                >
                  {getGroupNotificationCountData &&
                  getGroupNotificationCountData.code === "200" &&
                  getGroupNotificationCountData.data &&
                  getGroupNotificationCountData.data.eSeenCount ? (
                    getGroupNotificationCountData.data.eSeenCount === 0 ? (
                      <></>
                    ) : (
                      <span
                        className="notification-count"
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "#ff0000",
                          position: "absolute",
                          top: "-3px",
                          right: "-3px",
                          color: "#fff",
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "15px",
                        }}
                      >
                        {getGroupNotificationCountData.data.eSeenCount}
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                  <GroupsSVG />
                </div>
                <p>Groups</p>
              </div>
            </>
          )}
          <Link
            className={
              location.pathname === "/teams-settings" ||
              // location.pathname === "/teams-details" ||
              location.pathname === "/edit-team-details" ||
              location.pathname === "/settings"
                ? "menu active"
                : "menu"
            }
            to={
              location.pathname === "/add-team-member"
                ? "/teams-settings"
                : location.pathname === "/teams-details" ||
                  location.pathname === "/edit-team-details"
                ? "/edit-team-details"
                : "/settings"
            }
            state={{ prevPath: location.pathname }}
          >
            <div className="image-icon">
              <SettingsSVG />
            </div>
            <p>Settings</p>
          </Link>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  isMenuClose: PropsTypes.any,
};

export default AuthHeader;
